<template>
  <article class="box">
    <h1 class="page-title error--text">
      {{ localDocumentTitle }}
    </h1>
  </article>
</template>

<script>
import documentTitle from '../mixins/documentTitle'
export default {
  name: 'TheErrorPage',
  mixins: [documentTitle],

  data () {
    return {
      documentTitle: '$app.page_not_found'
    }
  }
}
</script>
